import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import noop from 'lodash/noop';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet-async";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack'
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { RouterLink } from '@src/components/core/link';
import { FormBase, TextField, Submit } from '@src/components/form';
import { useAuth, useMessage } from '@src/context/common';
import { useForm } from '@src/hooks';
import paths from '@src/paths';
import { DynamicLogo } from '@src/components/core/logo';
import config from '@src/config';


const metadata = { title: `Sign in | ${config.SITE.title}` };

const schema = Yup.object().shape({
  password: Yup.string()
    .required('This field is required'),
    // .min(8, 'Too Short!'),
  email: Yup.string('Enter your Email')
    .email('Enter a valid email')
    .required('Email is required'),
});

const DEFAULT_VALUES = {
  email: '',
  password: '',
};

function LoginForm({ onSubmit, onError, onSuccess }) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ schema, defaultValues: DEFAULT_VALUES });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <FormBase onSubmit={handleSubmit({ onSubmit, onError, onSuccess })}>
      <TextField
        label="Email address"
        control={control}
        margin="none"
        required
        fullWidth
        id="email"
        name="email"
        autoFocus
      />

      <TextField
        label="Password"
        control={control}
        margin="none"
        required
        fullWidth
        type={showPassword ? 'text' : 'password'}
        id="password"
        name="password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <Submit
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        fullWidth={true}
        loading={isSubmitting}
      >
        Sign in
      </Submit>
    </FormBase>
  );
}

LoginForm.displayName='LoginForm';
LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  OnSuccess: PropTypes.func,
};
LoginForm.defaultProps = {
  onSubmit: noop,
  onError: noop,
  OnSuccess: noop,
};

function Login() {
  const { login, isAuthenticated } = useAuth();
  const message = useMessage();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(paths.dashboard.overview)
    }
  }, [isAuthenticated]);

  const handleOnSubmit = useCallback((values) => {
    return login(values);
  }, [login]);

  const handleOnSuccess = useCallback(() => {
    message.close();
  }, [message]);

  const handleOnError = useCallback(
    (_, { isFieldError }) => {
      if (isFieldError) {
        message.error('Unable to log in with provided credentials.');
      } else {
        message.error('Unexpected server error.');
      }
    },
    [message],
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>{metadata.title}</title>
      </Helmet>
      <Stack spacing={4}>
        <div>
          <Box component={RouterLink} href={paths.home} sx={{ display: 'inline-block', fontSize: 0 }}>
            <DynamicLogo colorDark="light" colorLight="dark" height={32} width={122} />
          </Box>
        </div>
        <Stack spacing={1}>
          <Typography variant="h5">Sign in</Typography>
          {/*<Typography color="text.secondary" variant="body2">*/}
          {/*  Don&apos;t have an account?{' '}*/}
          {/*  <Link component={RouterLink} href={paths.auth.cognito.signUp} variant="subtitle2">*/}
          {/*    Sign up*/}
          {/*  </Link>*/}
          {/*</Typography>*/}
        </Stack>
        <Stack spacing={2}>
          <LoginForm
            onSubmit={handleOnSubmit}
            onSuccess={handleOnSuccess}
            onError={handleOnError}
          />
          <div>
            <Link component={RouterLink} href={paths.auth.cognito.resetPassword} variant="subtitle2">
              Forgot password?
            </Link>
          </div>
        </Stack>
      </Stack>
    </React.Fragment>
  );
}


Login.displayName = 'Login';
export default Login;
