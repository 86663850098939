'use client';

import * as React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import paths from '@src/paths';
import { useAuth } from '@src/context';

export default function GuestGuard({ children }) {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [isChecking, setIsChecking] = React.useState(true);
  const [searchParams] = useSearchParams();

  const checkPermissions = async () => {
    if (isAuthenticated) {
      console.debug('[GuestGuard]: User is logged in, redirecting to dashboard');
      navigate(searchParams.get('next') || paths.dashboard.overview, { replace: true });
      return;
    }

    setIsChecking(false);
  };

  React.useEffect(() => {
    checkPermissions().catch(() => {
      // noop
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Expected
  }, [isAuthenticated]);

  if (isChecking) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
}
